import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { useContentsId} from "../services/api/contents/use-contents";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function Content() {
  const { id } = useParams();
  const {data: contents, isSuccess} = useContentsId(id);
  console.log(JSON.stringify(contents));

  return (
      <div className="App">
          <div className="preloader-bg"></div>
          <div id="preloader">
              <div id="preloader-status">
                  <div className="preloader-position loader"><span></span></div>
              </div>
          </div>
          <Nav/>

          <div id="products" className="savoye-project section-padding" data-scroll-index="3">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12 text-center">
                          <div className="section-title">Nos lots</div>
                      </div>
                  </div>
                  <div className="row savoye-project-items">
                      {contents?.map(((item, index) => (
                          <div key={index}
                               className={`col-md-6 single-item interior ${index % 2 ? 'interior' : 'exterior'}`}>
                              <div className="savoye-project-wrap">
                                  <a href={`/contents/${item.id}/show`}><img src={item?.image?.url} alt=""/></a>
                                  <div className="savoye-project-content">
                                      {item.subtitle && (<p>{item.subtitle}</p>)}
                                      <h3><a href={`/contents/${item.id}/show`}>{item.title}</a></h3>
                                  </div>
                              </div>
                          </div>
                      )))}
                  </div>
              </div>
          </div>
          <div className=" section-padding"/>
          <div className=" section-padding"/>
          <hr className="line-vr-section"/>

          <Footer/>
      </div>
  );
}

export default Content;
