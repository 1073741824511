import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useContentId, useContentsId} from "../services/api/contents/use-contents";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

function Blog() {
  const { id } = useParams();
  const {data: content, isSuccess} = useContentId(id);
  console.log(JSON.stringify(content));

  return (
      <div className="App">
          <div className="preloader-bg"></div>
          <div id="preloader">
              <div id="preloader-status">
                  <div className="preloader-position loader"><span></span></div>
              </div>
          </div>
          <Nav />
          <div className="banner-header section-padding valign bg-img bg-fixed" data-overlay-dark="4"
               style={{backgroundImage: `url("${content?.image?.url}")`}}>
              <div className="container">
                  <div className="row">
                      <div className="col-md-12 text-center caption mt-90">
                          <h1>{content?.title}</h1>
                          <h5>{content?.subtitle}</h5>
                      </div>
                  </div>
              </div>
          </div>
          <hr className="line-vr-section"/>
          <div className="section-padding">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">
                          <p>{content?.description}</p>
                      </div>
                  </div>

              </div>
          </div>
          <hr className="line-vr-section"/>
          <Footer/>
      </div>
  );
}

export default Blog;
