import {useAuthTokenStore} from "../stores/token";

export const firstLetterUppercase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const logout = () => {
    useAuthTokenStore.setState({
        accessToken: null,
        user: null
    });
}

export const getQueryVariable = (variable) =>
{
    let query = window.location.search.substring(1);
    console.log(query)
    let vars = query.split("&");
    console.log(vars)
    for (let i=0;i<vars.length;i++) {
        let pair = vars[i].split("=");
        if(pair[0] === variable) {
            return pair[1];
        }
    }
    return false ;
}
