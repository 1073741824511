import {useMutation} from "react-query";
import useApi from "../axios";

export const useLead = () => {
    const { post } = useApi();

    return useMutation({
        mutationFn: async (data) => await post("/leads", data),
    });
    //return await post("/auth/login", data);
};
