import axios from "axios";

import { logout } from "../utils/utils";
import { useAuthTokenStore } from "../stores/token";

const useApi = () => {
  const { accessToken: authorizationBearer } = useAuthTokenStore();

  const axiosRequest = async ({
    baseURL,
    data,
    headers: customHeaders,
    method = "POST",
    params,
    timeout = 5000,
    url,
  }) => {
    const headers = {
      ...customHeaders,
    };

    if (authorizationBearer) {
      headers.authorization = `Bearer ${authorizationBearer}`;
    }

    try {
      const { data: response } = await axios({
        baseURL: baseURL ?? process.env.REACT_APP_API_URL,
        data,
        headers,
        method,
        params,
        timeout,
        url,
      });
      console.log("API URL", baseURL ?? process.env.REACT_APP_API_URL,);

      return response;
    } catch (err) {
      const formattedError = err;
      if ("response" in formattedError) {
        if (
          (formattedError?.response?.status === 401 &&
            formattedError?.response?.data?.message === "Unauthorized") ||
          (formattedError?.response?.status === 400 &&
            formattedError?.response?.data?.message === "USER_NOT_FOUND")
        ) {
          logout();
        }

        throw err.response;
      }

      throw err;
    }
  };

  const del = async (url, timeout) => {
    try {
      const response = await axiosRequest({
        method: "delete",
        url: url,
        timeout: timeout,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const put = async (url, body, timeout) => {
    try {
      const response = await axiosRequest({
        method: "put",
        url: url,
        data: body,
        timeout: timeout,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const patch = async (url, body, timeout) => {
    try {
      const response = await axiosRequest({
        method: "patch",
        url: url,
        data: body,
        timeout: timeout,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const post = async (url, body, headers = {}, baseURL, timeout) => {
    try {
      const response = await axiosRequest({
        method: "post",
        url: url,
        data: body,
        headers: headers,
        baseURL: baseURL,
        timeout: timeout,
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const postFile = async (url, body, timeout) => {
    try {
      const response = await axiosRequest({
        method: "post",
        url: url,
        data: body,
        headers: {
          accept: "application/json",
          "content-type": "multipart/form-data",
        },
        timeout: timeout,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const get = async (url, params, headers = {}, timeout) => {
    try {
      const response = await axiosRequest({
        method: "get",
        url: url,
        params: params,
        headers: headers,
        timeout: timeout,
      });
      return response;
    } catch (error) {
      console.error(error);
      if (error?.data?.message === "Signature has expired") {
        console.log("Signature has expired");
        logout() ;
      }
    }
  };

  return { del, get, patch, post, postFile, put, token: authorizationBearer };
};

export default useApi;
