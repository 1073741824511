import React from "react";

function Footer () {
  return (
      <>
          <div id="contact" className="topbanner-footer" data-scroll-index="7">
              <div className="section-padding banner-img valign bg-img bg-fixed" data-overlay-light="4"
                   data-background="img/slider/1.jpg">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-12 mb-30 text-left caption">
                              <div className="section-title">Contactez-nous</div>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-md-4">
                              <h6>Téléphone</h6>
                              <h5 className="mb-30">+33 (0) 7 50 09 58 44</h5>
                              <p className="mb-30">Lundi - Vendredi: 8h - 18h<br/>Samedi - Dimanche : 9h - 12h</p>
                          </div>
                          <div className="col-md-4">
                              <h6>Email</h6>
                              <h5 className="mb-30">faz@prescription-box.com</h5>
                              <p className="mb-30">24 King St, Charleston<br/>SC 29401 USA</p>
                          </div>
                          <div className="col-md-3 offset-md-1">
                              <div className="vid-area">
                                  <a className="play-button gallery-masonry-item-img-link d-block" data-type="iframe"
                                     data-fancybox="iframe"
                                     href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3354.758017847153!2d-79.93420398486563!3d32.77215479154045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fe7a1ae84ff639%3A0xe5c782f71924a526!2s24%20King%20St%2C%20Charleston%2C%20SC%2029401%2C%20USA!5e0!3m2!1sen!2str!4v1631170502143!5m2!1sen!2str">
                                      <svg className="circle-fill">
                                          <circle cx="43" cy="43" r="39" stroke="#272727" stroke-width="1"></circle>
                                      </svg>
                                      <svg className="circle-track">
                                          <circle cx="43" cy="43" r="39" stroke="none" stroke-width="1"
                                                  fill="none"></circle>
                                      </svg>
                                      <span className="polygon"><img src={"/img/location.png"} style={{width: 30}} alt={""} /></span> </a>
                                  <div className="cont mt-20 mb-30">
                                      <h5>Notre Location</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <footer className="main-footer dark">
              <div className="container">
                  <div className="row">
                      <div className="col-md-3 mb-30">
                          <div className="item abot">
                              <div className="logo mb-15"><img src="/img/logos/logo_pm_2_transparent.png" alt=""/></div>
                              <p>Bienvenue chez Prescription & Matériaux.</p>
                          </div>
                      </div>
                      <div className="col-md-4 offset-md-1 mb-30">
                          <div className="item usful-links">
                              <a className="fothead" href={"/services"}><h6>Nous</h6></a>
                              <ul>
                                  <li><a href="/#services">Nos services</a></li>
                                  <li><a href="/#products">Les lots</a></li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-md-4 mb-30">
                          <div className="item fotcont">
                              <div className="fothead">
                                  <h6>Parlons-en</h6>
                              </div>
                              <p>Vous avez un projet ?</p>
                              <a href={'/contact'}><p>Contactez-nous</p></a>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="sub-footer">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-8">
                              <div className="text-left">
                                  <p>© 2024, Prescription Matériaux. All right reserved.</p>
                              </div>
                          </div>
                          {/*<div className="col-md-4">
                              <div className="text-right-left">
                                  <p><a href="#">Terms of use</a> <span>|</span> <a href="#"> Privacy Environmental
                                      Policy </a></p>
                              </div>
                          </div>*/}
                      </div>
                  </div>
              </div>
          </footer>
      </>
  )
}

export default Footer;
