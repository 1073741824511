

function Coming() {

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            margin: '0',
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#f8f9fa',
            color: '#333',
        }}>

            <h1 style={{
                fontSize: '24px',
                textAlign: 'center'
            }}>Rendez-vous le 25 novembre pour découvrir nos offres</h1>
        </div>

    );
}

export default Coming;
