import React, {useState} from 'react';
import {useContents} from "../services/api/contents/use-contents";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import {useMember} from "../services/api/members/use-member";

function Home() {
    const {data: contents, isSuccess} = useContents();
    // const [services, setServices] = useState([]);
    //
    // useEffect(() => {
    //   if (contents) {
    //     setServices(contents.filter(content => content.content_type === "services"));
    //   }
    // }, [contents]);
    const quiSommesNous = contents?.find(content => content.content_type === "qui-sommes-nous");
    const services = contents?.filter(content => content.content_type === "services");
    const architecte = contents?.find(content => content.content_type === "architecte");
    const installateur = contents?.find(content => content.content_type === "installateur");
    const products = contents?.filter(content => content.content_type === "products");
    const blog = contents?.filter(content => content.content_type === "blog");

    const [name, setName] = useState('');
    const [firstname, setFirstname] = useState('');
    const [company, setCompany] = useState('');
    const [adresse, setAdresse] = useState('');
    const [codepostal, setCodepostal] = useState('');
    const [ville, setVille] = useState('');
    const [secteur, setSecteur] = useState('');
    const [region, setRegion] = useState('');
    const { mutateAsync: createMember } = useMember();

    const handleSubmit = async () => {
        try {
            const response = await createMember({ name, firstname, company, secteur, adresse, codepostal, ville, region });
            if (response) {
                setFirstname('');
                setCompany('');
                setAdresse('');
                setCodepostal('');
                setVille('');
                setSecteur('');
                setRegion('');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onsubmit = (e) => {
        e.preventDefault();
    }

    console.log(JSON.stringify(contents));
    return (
        <div className="App">
            <div className="preloader-bg"></div>
            <div id="preloader">
                <div id="preloader-status">
                    <div className="preloader-position loader"><span></span></div>
                </div>
            </div>
            <div className="progress-wrap cursor-pointer">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
                </svg>
            </div>
            <Nav />
            <header id="home" className="header slider-fade" data-scroll-index="0">
                <div className="owl-carousel owl-theme">
                    <div className="text-left item bg-img" >
                        <div className="v-middle caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7 mt-30">
                                        <div className="o-hidden">
                                            <img src="/img/logos/logo_pm_transparent_mini.png" alt=""/>
                                            <h2>Votre développement commercial passe<br/>aussi par la prescription.</h2>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="about-img">
                                            <div className="img"><img src="/img/home.png" className="img-fluid"
                                                                      alt=""/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div id="about" className="about section-padding" data-scroll-index="1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="section-title">{quiSommesNous?.title}</div>
                            {quiSommesNous?.description.split('\u000b').map(item => (<p>{item}</p>))}
                        </div>
                        <div className="col-md-5">
                            <div className="about-img">
                                <div className="img"><img src="/img/about.png" className="img-fluid" alt=""/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="line-vr-section"/>

            <div id="services" className="services-feat section-padding" data-scroll-index="2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-title">Nos Services</div>
                        </div>
                    </div>
                    <div className="services-feat section-padding">
                        <div className="container">
                            <div className="row">
                                {services?.map(((item, index) => (
                                    <div key={index} className="col-md-4">
                                        <div className="square-flip">
                                            {/*<div className="square bg-img" style={"background-image: url('"${process.env.REACT_APP_BASE_URL+services?.[0]?.image?.url}"');">*/}

                                            <div className="square bg-img" style={{backgroundImage: `url("${item.image?.url}")`}}>
                                                <div className="square-container d-flex align-items-end justify-content-end">
                                                    <div className="box-title text-vertical">
                                                        <h4>{item.title}</h4>
                                                    </div>
                                                </div>
                                                <div className="flip-overlay"></div>
                                            </div>
                                            <div className="square2">
                                                <div className="square-container2">
                                                    <h4>{item.title}</h4>
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="line-vr-section"/>

            <div id="architecte" className="about section-padding" data-scroll-index="3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="section-title">{architecte?.title}</div>
                            {architecte?.description.split('\u000b').map(item => (<p>{item}</p>))}
                        </div>
                        <div className="col-md-5">
                            <div className="about-img">
                                <div className="img"><img src="/img/about.png" className="img-fluid" alt=""/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="line-vr-section"/>

            <div id="architecte" className="about section-padding" data-scroll-index="4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="section-title">{installateur?.title}</div>
                            {installateur?.description.split('\u000b').map(item => (<p>{item}</p>))}
                        </div>
                        <div className="col-md-5">
                            <div className="about-img">
                                <div className="img"><img src="/img/about.png" className="img-fluid" alt=""/></div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <h6>Devenez membre du réseau P&M</h6>
                            <form onSubmit={onsubmit} className="row">
                                <div className="col-md-12">
                                    <input
                                        placeholder="Nom *"
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Prénom *"
                                        type="text"
                                        id="firstname"
                                        name="firstname"
                                        value={firstname}
                                        onChange={(e) => setFirstname(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Entreprise *"
                                        type="text"
                                        id="company"
                                        name="company"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Adresse *"
                                        type="text"
                                        id="adresse"
                                        name="adresse"
                                        value={adresse}
                                        onChange={(e) => setAdresse(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Code postal *"
                                        type="text"
                                        id="codepostal"
                                        name="codepostal"
                                        value={codepostal}
                                        onChange={(e) => setCodepostal(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input
                                        placeholder="Ville *"
                                        type="text"
                                        id="ville"
                                        name="ville"
                                        value={ville}
                                        onChange={(e) => setVille(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input
                                        placeholder="Région *"
                                        type="text"
                                        id="region"
                                        name="region"
                                        value={region}
                                        onChange={(e) => setRegion(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <select id="secteur" value={secteur} onChange={(e) => setSecteur(e.target.value)}>
                                        <option value="">Secteur *</option>
                                        {products?.map(((item, index) => (<option value={item.id}>{item.title}</option>)))}
                                    </select>
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" onClick={() => handleSubmit()}>Envoyer</button>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>


            <hr className="line-vr-section"/>

            <div id="products" className="savoye-project section-padding" data-scroll-index="5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-title">Nos lots</div>
                        </div>
                    </div>
                    <div className="row savoye-project-items">
                        {products?.map(((item, index) => (
                            <div className={`col-md-6 single-item interior ${index % 2 ? 'interior' : 'exterior'}`}>
                                <div className="savoye-project-wrap">
                                    <a href={`/contents/${item.id}`}><img src={item.image?.url} alt=""/></a>
                                    <div className="savoye-project-content">
                                        {item.subtitle && (<p>{item.subtitle}</p>)}
                                        <h3><a href={`/contents/${item.id}`}>{item.title}</a></h3>
                                    </div>
                                </div>
                            </div>
                        )))}
                    </div>
                </div>
            </div>

            {/*<div className=" section-padding"/>
        <div className=" section-padding"/>*/}

            <div className=" section-padding"/>
            <div className=" section-padding"/>
            <div className=" section-padding"/>
            <hr className="line-vr-section"/>
            <div className=" section-padding"/>

            <div id="blog" className="blog " data-scroll-index="6">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">Blog</div>
                        </div>
                    </div>
                    <div className="row">
                        {blog?.map(((item, index) => (
                            <div className="col-md-4">
                                <div className="item">
                                    <div className="post-img">
                                        <a href={`/blog/${item.id}`}>
                                            <div className="img"><img src={item.image.url} alt={`Blog ${item.id}`}/></div>
                                        </a>
                                    </div>
                                    <div className="cont">
                                        <h4><a href={`/blog/${item.id}`}>{item.title}</a></h4>
                                        <div className="info"><a
                                            href={`/blog/${item.id}`}><span>{item.subtitle}</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )))}
                    </div>
                </div>
            </div>
            <hr className="line-vr-section"/>
            <Footer/>
        </div>
    );
}

export default Home;
